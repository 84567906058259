import React from "react"
import { Header } from "../components/HeaderSection"
import { AboutUsSection } from "../components/AboutUs"
import { SisterConcerns } from "../components/SisterConcerns"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"

const AboutUs = () => {
  return (
    <section className="bg-body-lightest">
      <SEO
        title="About Us"
        description="Established in 2000, environmental solutions started as an environmental consultancy and now provides design, supply erection and commissioning services for pools, fountains, water and sewage treatment plants"
      />
      <Header />
      <AboutUsSection />
      <SisterConcerns />
      <Footer />
    </section>
  )
}

export default AboutUs
