import React, { useState, useEffect } from "react"
import { Container } from "../components/Container"
import { AnimationWrapper } from "./AnimationWrapper"
import { useInView } from "react-hook-inview"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const headerStyles =
  "font-semibold text-3xl text-primary font-sans pb-3 pt-10 text-center sm:text-4xl"

export const SisterConcerns = () => {
  const [animateSisCon, setAnimateSisCon] = useState(false)
  const [ref, isVisible] = useInView()
  useEffect(() => {
    if (isVisible && !animateSisCon) {
      setAnimateSisCon(true)
    }
  }, [animateSisCon, isVisible])

  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "esys.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "fibfab.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="pb-16 bg-body-lightest z-10 relative" ref={ref}>
      {animateSisCon && (
        <Container>
          <h1 className={headerStyles}>Our Sister Concerns</h1>
          <AnimationWrapper
            animationType="slideToLeft"
            className="mt-2 mb-6 flex flex-col bg-white md:flex-row md:mx-8 lg:mx-16"
            delay="0.5"
          >
            <div className="md:w-1/2">
              <Img
                className="object-cover h-full"
                fluid={data.image1.childImageSharp.fluid}
              />
            </div>
            <div className="p-8 md:w-1/2">
              <h2 className="font-bold mb-6 text-center text-2xl font-sans text-primary">
                Environmental Systems
              </h2>
              <h3 className="text-lg font-bold mb-2">
                Exclusive sales division with 10,000 sqft showroom
              </h3>
              <ul className="leading-normal mb-6 service-list">
                <li> Pool equipments</li>
                <li>Water filtration equipments</li>
                <li>Pollution control equipments</li>
              </ul>
              <p>
                To know more, visit{" "}
                <a
                  className="text-secondary underline"
                  href="http://www.environmentalsystems.in/"
                >
                  our website
                </a>
              </p>
            </div>
          </AnimationWrapper>
          <AnimationWrapper
            animationType="slideToRight"
            className="mb-6 flex flex-col bg-white md:flex-row-reverse md:mx-8 lg:mx-16"
            delay="1"
          >
            <div className="md:w-1/2">
              <Img
                className="object-cover h-full"
                fluid={data.image2.childImageSharp.fluid}
              />
            </div>
            <div className="p-8 md:w-1/2">
              <h2 className="font-bold mb-6 text-center text-2xl font-sans text-primary">
                Fiber Fabrica
              </h2>
              <h3 className="text-lg font-bold mb-2">
                FRP/GRP fabrication unit
              </h3>
              <ul className="leading-normal mb-6 service-list">
                <li>water storage/treatment tanks</li>
                <li>bio-digester/biogas plants</li>
                <li>readymade swimming pools/Jacuzzi on FRP</li>
              </ul>
              <p className="leading-normal">
                Our readymade pool cum Jacuzzi is already a market favorite with
                about 50 installations.These are fabricated based on custom
                design with many exclusive features, at our factory at Aluva and
                transported and installed at the site within an impressively
                short time.
              </p>
            </div>
          </AnimationWrapper>
        </Container>
      )}
    </section>
  )
}
