import React from "react"
import { Container } from "../components/Container"
import { SplitLetters } from "../components/SplitLetters"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const headerStyles =
  "font-semibold text-3xl text-primary font-sans pb-3 pt-10 text-center sm:text-4xl"

export const AboutUsSection = () => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "aboutus_hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <section>
      <div className="fixed top-0 w-full h-full">
        <Img
          fluid={desktop.childImageSharp.fluid}
          className="absolute top-0 w-full h-full"
        />
      </div>
      <div className="z-10 bg-body-lightest relative">
        <Container>
          <div className="content-wrapper">
            <h1 className={headerStyles}>
              <SplitLetters text="Who Are We" />
            </h1>
            <p>
              We are Kerala's leading player in the design and commissioning of
              swimming pools, water treatment, sewage treatment plants and
              fountains for more than a decade. In all our plants, we are
              undertaking the whole installation process including design of the
              plant, supply erection and commissioning of all electromechanical
              equipments, plumbing and wiring works. The civil works are
              undertaken and outsourced upon client request.
            </p>
          </div>
        </Container>
      </div>
      <div className="h-96"></div>
      <div className="z-10 bg-body-lightest relative">
        <Container>
          <div className="content-wrapper">
            <h1 className={headerStyles}>Our Story</h1>
            <p>
              <span className="uppercase text-lg text-primary sm:text-2xl">
                Environmental Solutions{" "}
              </span>
              started its operations in the year 2000 as an environmental
              consultancy firm, headed by{" "}
              <span className="text-primary font-semibold">
                Vincent J Mechery
              </span>
              , a scientist with more than 25 years of experience in the field
              with leading government organizations like Central Pollution
              Control Board, National Thermal Power Corporation, Neyveli Lignite
              Corporation and Royal Commission of Saudi Arabia.
            </p>
            <p>
              Subsequently in December 2001, we have established an
              environmental laboratory in Aluva, to widen our area of
              activities. The laboratory is engaged in testing all pollution
              parameters in air, water, wastewater, stack emissions and noise
              levels. Our lab is approved by Kerala pollution Control Board for
              Environmental Monitoring. In order to support the environmental
              monitoring works undertaken from M/s. St-CMS Electric Company Pvt.
              Ltd at Neyveli, Tamil Nadu, we started a field laboratory at
              Neyveli in 2001. We are serving the international company, ST-CMS
              since its inception.
            </p>
            <p>
              We have further elaborated our activities into the business of
              design, supply, erection and commissioning of water treatment
              plants, sewage treatment plants and swimming pools from April
              2002. From April 2007, we have changed the status of the firm to
              partnership, including more members.
            </p>
            <p>
              Within a short period, we earned a strong satisfied client base of
              more than <span className="text-primary font-semibold">300 </span>
              clients in Kerala.
            </p>
          </div>
        </Container>
      </div>
    </section>
  )
}
